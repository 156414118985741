<script setup lang="ts">
import { computed } from "vue";
import { isEmpty } from "lodash-es";
import { apiPathUrl } from "@/Utils/Helpers";
import { useForm } from "@inertiajs/vue3";
import { toast } from "vue-sonner";
import AppForm from "@/Components/Shared/forms/AppForm.vue";
import AppText from "@/Components/Shared/text/AppText.vue";
import AppFormGroup from "@/Components/Shared/forms/AppFormGroup.vue";
import AppFormInputTextarea from "@/Components/Shared/forms/AppFormInputTextarea.vue";
import AppFormFooter from "@/Components/Shared/forms/AppFormFooter.vue";
import AppButtonGroup from "@/Components/Shared/button/AppButtonGroup.vue";
import AppButton from "@/Components/Shared/button/AppButton.vue";
import AppRulesPopover from "@/Components/App/interface/AppRulesPopover.vue";

type Props = {
    itemId: string;
    path: string;
    type: string;
};
const { itemId, path, type } = defineProps<Props>();

const emit = defineEmits(["close", "success", "added"]);

const form = useForm({
    reason: "",
});

// methods
const onSubmit = () => {
    form.post(`${apiPathUrl(path)}/flag`, {
        preserveScroll: true,

        onError: () => {
            // console.log(error);
            toast.success("Er ging iets mis bij het rapporteren.", "error");
        },

        onSuccess: (e: any) => {
            const parsed = JSON.parse(JSON.stringify(e));
            if (isEmpty(parsed.props.errors)) {
                emit("success");
                toast.success("Rapportage succesvol verstuurd.");
            }
        },
    });
};
const cancelFlag = () => {
    emit("close");
};

// computed
const goodToGo = computed(() => {
    return !!form.reason;
});
</script>

<template>
    <AppForm @submit.prevent="onSubmit">
        <AppText class="mb-2">
            <p>
                Wij nemen gerapporteerde onderdelen op TCGers.nl behoorlijk
                serieus, en zullen deze beoordelen aan de hand van onze
                <AppRulesPopover />.
            </p>
        </AppText>

        <AppFormGroup>
            <AppFormInputTextarea
                v-model="form.reason"
                autosize
                name="reason"
                :min-height="200"
                label="Reden"
            />
        </AppFormGroup>

        <AppFormFooter>
            <AppButtonGroup
                variant="spaced"
                class="grow"
            >
                <AppButton
                    variant="cancel"
                    @click="cancelFlag"
                >
                    Annuleren
                </AppButton>

                <AppButton
                    :disabled="!goodToGo"
                    :loading="form.processing"
                    color="primary"
                    type="submit"
                    size="small"
                >
                    Verstuur
                </AppButton>
            </AppButtonGroup>
        </AppFormFooter>
    </AppForm>
</template>
